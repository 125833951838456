.generatorMainDiv {
  padding: 0%;
  margin: 0%;
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  // user-select: none;

  .heading {
    padding: 0%;
    margin: 5rem;
    margin-top: 1rem;
    > h1 {
      font-family: "Poor Story", cursive;
    }
  }

  .generatorDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border: 1px solid black;
    border-radius: 1rem;
    padding: 1rem;
    width: 25vw;
    background-color: cadetblue;
    > input {
      font-size: 20px;
      width: 15vw;
      outline: none;
      border: none;
      background-color: cadetblue;
    }
    .icons {
      color: rgb(255, 255, 255);
      font-size: 25px;
      cursor: pointer;
    }
  }

  .specialCharsToggle {
    width: 25vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5rem;
    padding-bottom: 3rem;

    > p {
      font-size: 130%;
      font-family: "Montserrat", sans-serif;
      padding-right: 0.5rem;
    }
  }
  .slider {
    // width: ;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 5rem;
    padding-top: 0;
    > p {
      padding-left: 1rem;
      font-size: 130%;
      font-family: "Montserrat", sans-serif;
    }
  }
}
